import React, { useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectUserAccount } from "../../redux/user/user.selector";
import { UserRole } from "../../constants";

 // eslint-disable-next-line
import { ReactComponent as CogIcon } from "../../assets/cog.svg";
import { ReactComponent as ApprovalIcon } from "../../assets/approval.svg";
import { ReactComponent as SignoutIcon } from "../../assets/signout.svg";
import { ReactComponent as ComputerIcon } from "../../assets/computer.svg";
import { ReactComponent as HouseIcon } from "../../assets/house.svg";
import { ReactComponent as PaletteIcon } from "../../assets/palette.svg";
import { ReactComponent as GearIcon } from "../../assets/gear.svg";
import DashboardIcon from "../../assets/dashboard.png";
import FaqIcon from "../../assets/faq.png";
import { NavItemLink, NavItemStyle } from "../NavItem/nav-item.component";
import SignOut from "../Signout/signout.component";

import { NavHeader, NavWrapper, Title, LogoImg, Subtitle } from "./nav.styles";
import logo from "../../assets/logo.png";
import helpIcon from "../../assets/help-icon.png";
import { SITE_BACKEND_FULL_URL } from '../../server';

const Nav = ({ userAccount }) => {
  
  const adUserAdministrationURL = SITE_BACKEND_FULL_URL.replace('/artist-dashboard/backend', '/ad-user-administration/') + '?email_id='+userAccount.contactEmail;

  return (<NavHeader>
      <Title>
        <LogoImg src={logo} alt="Teefury Logo" />
        Tee<b>Fury</b>
      </Title>
      <Subtitle>Dashboard</Subtitle>
      <NavWrapper>
        {userAccount.isAdmin ? (
          <>
            <NavItemLink
              to="/admin/profile"
              pathToMatch="/profile"
              Icon={HouseIcon}
            >
              Home
            </NavItemLink>
            <NavItemLink
              to="/admin/art-submissions/grid/new"
              pathToMatch="/art-submissions"
              Icon={ApprovalIcon}
            >
              Art Review
            </NavItemLink>
            <NavItemLink
              to="/admin/calendar"
              pathToMatch="/calendar"
              Icon={ApprovalIcon}
            >
              Calendar
            </NavItemLink>
            <NavItemLink
              to="/admin/commissions"
              pathToMatch="/admin/commissions"
              Icon={CogIcon}
            >
              Commissions
            </NavItemLink>
            <NavItemLink
              to="/admin/artists-profiles"
              pathToMatch="/artists-profiles"
              Icon={PaletteIcon}
            >
              Artist's Profiles
            </NavItemLink>
            
            <NavItemLink
              to="/admin/reporting"
              pathToMatch="/admin/reporting"
              Icon={CogIcon}
            >
              Reporting 
            </NavItemLink>

            {userAccount.userRole !== UserRole.ADMIN_VIEW_ONLY ? (
              <>
              <NavItemLink
                to="/admin/settings"
                pathToMatch="/settings"
                Icon={GearIcon}
              >
                Settings
              </NavItemLink>

            
              <NavItemStyle>
                  <img alt="" src={helpIcon}  style={{ width: "2.15rem"}}/>
                  <a style={{textDecoration: "none"}} href={`javascript:void(window.open('${adUserAdministrationURL}', 'blank'))`}>
                      <NavItemStyle style={{margin: "0 0 0 .5rem", padding:" 0 0 0 .25rem"}}>
                        Users
                      </NavItemStyle>
                  </a>
              </NavItemStyle>
              </>
            ) : null }
          </>
        ) : (
          <>
            <NavItemLink
              to="/artist/dashboard"
              pathToMatch="/dashboard"
              IconImage={DashboardIcon}
            >
              Home
            </NavItemLink>
            <NavItemLink
              to="/artist/profile"
              pathToMatch="/profile"
              Icon={HouseIcon}
            >
              My Dashboard
            </NavItemLink>
            <NavItemLink
              to="/artist/submissions"
              pathToMatch="/submissions"
              Icon={ComputerIcon}
            >
              Submissions
            </NavItemLink>
            <NavItemLink
              to="/artist/commissions"
              pathToMatch="/commissions"
              Icon={CogIcon}
            >
              Commissions
            </NavItemLink>
            {/* <NavItemLink
              to="/artist/notifications"
              pathToMatch="/notifications"
              Icon={BellIcon}
            >
              Notifications
            </NavItemLink> */}
          </>
        )}
        <NavItemStyle Icon={SignoutIcon}>
          <SignOut />
        </NavItemStyle>

          <NavItemLink
              to="/help"
              pathToMatch="/help"
              Icon={CogIcon}
            >
              Need Help? Contact Us
          </NavItemLink>
          <NavItemStyle>
              <img alt="" src={FaqIcon}  style={{ width: "25px"}}/>
              <a style={{textDecoration: "none"}} href="javascript:void(window.open('https://artiststeefury.gorgias.help/en-US', 'blank'))">
                  <NavItemStyle style={{margin: "0 0 0 15px", padding:" 0 0 0 5px"}}>
                    FAQ
                  </NavItemStyle>
              </a>
          </NavItemStyle>


        {/* <NavItemStyle>
                <img alt="" src={helpIcon}  style={{ width: "2.15rem"}}/>
              <a
              style={{textDecoration: "none"}}
                  href="javascript:void(
          window.open(
            'https://form.jotform.com/202934719139158',
            'blank',
            'scrollbars=yes,
            toolbar=no,
            width=700,
            height=500'
          )
        )
      ">
        <NavItemStyle style={{margin: "0 0 0 .5rem", padding:" 0 0 0 .25rem"}}>
        Need Help? Contact Us
        </NavItemStyle>
      </a>
              </NavItemStyle> */}
      </NavWrapper>
    </NavHeader>
  );
}

const mapStateToProps = createStructuredSelector({
  userAccount: selectUserAccount,
});

export default connect(mapStateToProps)(Nav);
