import { DialogContent, Dialog, withStyles, IconButton, MenuItem, Select } from "@material-ui/core";
import { DialogTitle } from "../Dialog";
import React, { useState } from "react";
import CloseIcon from '@material-ui/icons/Close';
import { SubmissionArtworkInfoInterface } from "./admin-art-submission-types";
import { BtnArtSubmit } from "../Button";
import { GreyTextArea } from "../AdminArtApproval/admin-art-approval.styles";
import { buttonAndTextFontStyle } from "./common-styles";
import { ArtworkInfoType } from "../../constants";

const CustomDialog = withStyles((theme) => ({
  paperWidthSm: {
    maxWidth: "none"
  },
}))(Dialog);

interface AdminArtArtworkInfo {
  handleClose: () => void;
  isOpen: boolean;
  artworkInfo: SubmissionArtworkInfoInterface[];
  artworkInfoType: ArtworkInfoType,
  onChangeType: (type: ArtworkInfoType) => void
}

const AdminArtArtworkInfo = ({ handleClose, isOpen, artworkInfo, onChangeType, artworkInfoType }: AdminArtArtworkInfo) => {

  const [copiedSuccess, setCopiedSuccess] = useState(false);

  const copyTable = () => {
    const elTable = document.querySelector('table#artwork_info_table');
    
    let range, sel;
    
    // Ensure that range and selection are supported by the browsers
    if (document.createRange && window.getSelection) {
    
      range = document.createRange();
      sel = window.getSelection();
      // unselect any element in the page
      if (sel && elTable) {
        sel.removeAllRanges();
        
        try {
          range.selectNodeContents(elTable);
          sel.addRange(range);
        } catch (e) {
          range.selectNode(elTable);
          sel.addRange(range);
        }
      
        document.execCommand('copy');
        sel.removeAllRanges();

        setCopiedSuccess(true);

        setTimeout(() => {
          setCopiedSuccess(false);
        }, 3000);
      }
    }
    
    // sel.removeAllRanges();
    
    console.log('Element Copied! Paste it in a file')
  }
  return (
      <div>
        <CustomDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={isOpen}>
          
          <IconButton style={{ position: 'absolute', right: 0 }} aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>

          <div style={{ marginTop: '40px'}}>
            <h2 style={{margin: 0, textAlign: 'center'}}>ARTWORK INFO</h2>
          </div>

          <div style={{ width: '50%', margin: 'auto', marginTop: '30px', display: 'flex', justifyContent: 'space-between', fontWeight: 'bold', alignItems: 'center' }}>
            <div>
              <span style={{ color: '#53565A'}}>Status: </span>
              <span style={{ color: '#117982', backgroundColor: '#D2EDEE', borderRadius: "20px", padding: "10px 20px", marginLeft: '10px' }}>REVIEWED</span>
            </div>

            <div style={{ display: 'flex', gap: '10px',  alignItems: 'center' }}>
              <span style={{ color: '#53565A'}}>Schedule Type: </span>
              <GreyTextArea style={{ backgroundColor: "#d2edee", margin: 0 }}>
                <Select
                  value={artworkInfoType}
                  onChange={(e) => onChangeType(e.target.value as ArtworkInfoType)}
                  disableUnderline
                  displayEmpty
                  style={{
                    ...buttonAndTextFontStyle,
                    width: "100%"
                  }}
                >
                    {['ALL', 'ODAD', 'WEEKLY'].map((scheduleTag) => (
                      <MenuItem key={scheduleTag} value={scheduleTag} style={buttonAndTextFontStyle}>{scheduleTag}</MenuItem>
                    ))}
                </Select>
              </GreyTextArea>
            </div>
          </div>

          <DialogContent>
            <div style={{width: "800px"}}>
              <table id="artwork_info_table" className="table-artwork-info" style={{ marginTop: "50px", marginBottom: "50px" }}>
                <tr>
                  <th style={{ width: '50%' }}>TITLE</th>
                  <th style={{ width: '50%' }}>ARTIST</th>
                </tr>
                {artworkInfo.map((a) => <tr>
                  <td style={{ backgroundColor: '#D9D9D9' }}>{ a.title }</td>
                  <td>{ a.artistName }</td>
                </tr>
                )}
              </table>

              {copiedSuccess && <p style={{ color: 'green', textAlign: 'center' }}>Table copied successfully.</p>}

              <div style={{ marginBottom: '30px' }}>
                <BtnArtSubmit
                  type="button"
                  textAlign="center"
                  style={{ backgroundColor: "#0B7C80", cursor: "pointer", marginLeft: "20px" }}
                  onClick={copyTable}
                  >
                    Copy Text
                  </BtnArtSubmit>
              </div>
              
            </div>
          </DialogContent>
        </CustomDialog>
      </div>
    );
}

export default AdminArtArtworkInfo;