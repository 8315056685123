import React, { useEffect, useRef, useState } from "react"
import { ArtistFilterWrapper } from "../SharedStyle/art-submissions.styles"
import { useOnClickOutside } from "../../utils/hooks";

interface CustomSelectProps {
  options: {
    value: string,
    label: string, 
  }[];
  selectedLabel: string | null;
  width?: number;
  name?: string,
  handleChange: Function;
}

const CustomSelect = ({ selectedLabel, options, width, name, handleChange }: CustomSelectProps) => {

  const [openOption, setOpenOption] = useState(false);
  const calRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    console.log('CustomSelect: ');
    console.log(options);
  }, []);

  useOnClickOutside(calRef, () => {
    setOpenOption(false);
  });

  const onChange = (value: string) => {
    if (name) {
      handleChange({ target: { name, value } });  
    } else {
      handleChange(value);
    }
    setOpenOption(false);
  }

  return <>
  <div className="custom-select-input-text" style={{ display: 'flex', position: 'relative', borderWidth: ( name ? 0 : '2px' ) }}>
    <div style={{ width: width ? (width == 100 ? '100%' : width+'px') : '200px', cursor: 'pointer' }} onClick={() => setOpenOption(true)}>
      <label style={{ cursor: 'pointer' }}>{selectedLabel}</label>
      <svg className="MuiSvgIcon-root MuiSelect-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M7 10l5 5 5-5z"></path></svg>
    </div>

    <div ref={calRef} style={{position: "absolute", left: "0", top: "35px", zIndex: 99 }}>
      { openOption && <ArtistFilterWrapper style={{ padding: '10px 0' }}>
          { options.map(o => <label key={o.value} className={`${ selectedLabel == o.label ? 'active' : '' } custom-select-option`} onClick={() => onChange(o.value)}>{o.label}</label>)}
        </ArtistFilterWrapper>
      }
    </div>
  </div>
</>
}

export default CustomSelect;